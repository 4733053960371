<!--
 * @Author: lbh
 * @Date: 2021-04-21 14:26:35
 * @LastEditors: lbh
 * @LastEditTime: 2024-01-10 09:28:41
 * @Description: file content
-->
<template>
  <div class="self-header">
    <selfDownload :type="type" />
    <div
      class="header_1"
      v-if="type == '1000'"
    >
      <img :src="isRicepon ? getImgPath('ricepon_logo.png') : getImgPath('gingersolt_logo.png')" />
      <div class="items">
        <div
          class="item pointer-hover"
          :class="ad=='menu'?'item-active':''"
          @click="goUrl('/')"
        >{{ $t('header.homePage') }}</div>
        <div
          class="item pointer-hover"
          :class="ad=='about'?'item-active':''"
          @click="goUrl('/about')"
        >{{ $t('header.aboutGingersoft') }}</div>
        <div
          v-if="!isRicepon"
          class="item pointer-hover"
          :class="ad=='products'?'item-active':''"
          @click="goUrl('/products')"
        >
          <el-dropdown>
            <div class="el-dropdown-link">
              {{ $t('header.product') }}
            </div>
            <el-dropdown-menu
              class="dropdown-menu"
              slot="dropdown"
            >
              <el-dropdown-item>
                <div
                  class="pointer-hover"
                  @click="goUrl('/deliverySystem')"
                  :class="child == 'deliverySystem' ? 'child-active' : ''"
                >{{ $t('header.DeliverySystem') }}</div>
              </el-dropdown-item>
              <el-dropdown-item>
                <div
                  class="pointer-hover"
                  @click="goUrl('/products')"
                  :class="child == 'products' ? 'child-active' : ''"
                >{{ $t('header.CateringSystem') }}</div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div
          v-else
          class="item pointer-hover"
          @click="goUrl('/products')"
          :class="ad=='products'?'item-active':''"
        >
          <el-dropdown>
            <div class="el-dropdown-link">
              {{ $t('header.product') }}
            </div>
            <el-dropdown-menu
              class="dropdown-menu"
              slot="dropdown"
            >
              <el-dropdown-item>
                <div
                  class="pointer-hover"
                  @click="goUrl('/gspos')"
                  :class="child == 'gspos' ? 'child-active' : ''"
                  v-text="isRicepon ? $t('header.GSPOSOrderSystem') : $t('header.GSPOSCateringManagementSystem')"
                ></div>
              </el-dropdown-item>
              <el-dropdown-item>
                <div
                  class="pointer-hover"
                  @click="goUrl('/gsa')"
                  :class="child == 'gsa' ? 'child-active' : ''"
                  v-text="isRicepon ? $t('header.GSAMobileOrderingSystem') : $t('header.CloudBasedCateringManagementSystem')"
                ></div>
              </el-dropdown-item>
              <el-dropdown-item v-if="isRicepon">
                <div
                  class="pointer-hover"
                  @click="goUrl('/gsq')"
                  :class="child == 'gsq' ? 'child-active' : ''"
                >{{ $t('header.GSQSelfServiceQueuingSystem') }}</div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div
          class="item pointer-hover"
          :class="ad=='ricepon'?'item-active':''"
          v-if="isRicepon"
          @click="goUrl('/ricepon')"
        >
          <el-dropdown>
            <div class="el-dropdown-link pointer-hover">
              {{ $t('header.ricepon_') }}
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <div
                  class="pointer-hover"
                  @click="goUrl('/qrcode')"
                  :class="child == 'qrcode' ? 'child-active' : ''"
                >{{ $t('header.QrCodeOrdering') }}</div>
              </el-dropdown-item>
              <el-dropdown-item>
                <div
                  class="pointer-hover"
                  @click="goUrl('/vip')"
                  :class="child == 'vip' ? 'child-active' : ''"
                >{{ $t('header.MembershipSystem') }}</div>
              </el-dropdown-item>
              <el-dropdown-item>
                <div
                  class="pointer-hover"
                  @click="goUrl('/takeOutOrPickUp')"
                  :class="child == 'takeOutOrPickUp' ? 'child-active' : ''"
                >{{ $t('header["Delivery-SelfPickup"]') }}</div>
              </el-dropdown-item>
              <!-- <el-dropdown-item>
                <div
                  class="pointer-hover"
                  @click="goUrl('/reservation')"
                  :class="child == 'reservation' ? 'child-active' : ''"
                >{{ $t('header["Online booking"]') }}</div>
              </el-dropdown-item> -->
              <!-- <el-dropdown-item>
                <div
                  class="pointer-hover"
                  @click="goUrl('/lineUp')"
                  :class="child == 'lineUp' ? 'child-active' : ''"
                >{{ $t('header["Queue up to collect tickets"]') }}</div>
              </el-dropdown-item> -->
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <!-- <div
          v-if="!isRicepon"
          class="item pointer-hover"
          :class="ad=='business'?'item-active':''"
          @click="goUrl('/business')"
        >網上付款服務 PayMe for Business (快將推出)</div> -->
        <div
          v-if="isRicepon"
          class="item pointer-hover"
          :class="ad=='case'?'item-active':''"
          @click="goUrl('/case')"
        >
          <el-dropdown>
            <div
              class="el-dropdown-link pointer-hover"
              v-text="$t('header[\'Customer Stories\']')"
            >
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <div
                  class="pointer-hover"
                  @click="goUrl('/biefu')"
                  :class="child == 'biefu' ? 'child-active' : ''"
                >{{ $t('header["Beifu Group"]') }}</div>
              </el-dropdown-item>
              <el-dropdown-item>
                <div
                  class="pointer-hover"
                  @click="goUrl('/daxiwu')"
                  :class="child == 'daxiwu' ? 'child-active' : ''"
                >{{ $t('header["Daikiya Group"]') }}</div>
              </el-dropdown-item>
              <el-dropdown-item>
                <div
                  class="pointer-hover"
                  @click="goUrl('/huangzhengzheng')"
                  :class="child == 'huangzhengzheng' ? 'child-active' : ''"
                >{{  $t('header["Huang Zhenzhen Thai Restaurant"]') }}</div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div
          v-else
          class="item pointer-hover"
          :class="ad=='case'?'item-active':''"
          @click="goUrl('/case')"
        >{{ $t('header["Our customers"]') }}</div>
        <!-- <div
          class="item pointer-hover"
          :class="ad=='contact'?'item-active':''"
          @click="goUrl('/contact')"
        >聯絡我們</div> -->
        <div
          v-if="isRiceponCn"
          class="item"
        >
          <el-dropdown
            class="check_language_t"
            @command="checkLanguage"
          >
            <span class="el-dropdown-link">
              {{$i18n.locale == 'hk' ? '中文繁體' : '中文简体'}}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu
              class="check_language_m"
              slot="dropdown"
            >
              <el-dropdown-item command="hk">中文繁體</el-dropdown-item>
              <el-dropdown-item command="zh">中文简体</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div
          class="btn theme_bg_color  pointer-hover"
          @click="goUrl('/freeTrial')"
          v-if="isRicepon"
        >
          {{ $t('header["Free trial"]') }}
        </div>
      </div>
    </div>
    <div
      class="header_2"
      v-else
    >
      <img :src="isRicepon ? getImgPath('ricepon_logo.png')  : getImgPath('gingersolt_logo.png')" />
      <i
        class="el-icon-s-operation pointer-hover"
        style="font-size:24px"
        @click="drawer.show = true"
      ></i>
    </div>
    <!-- 菜單 -->
    <el-drawer
      title=""
      :visible.sync="drawer.show"
      direction="rtl"
      :modal="true"
      :size="type == '600' ? '60%' : '30%'"
    >
      <selfMenu
        :menus="menus"
        :active="ad"
        :childActive="child"
      />
    </el-drawer>
  </div>
</template>
<script>
import util from "@/js/utils/util";
import "@/store/style.css";
import selfMenu from "@/components/commo/menu";
import selfDownload from "@/components/commo/download";
export default {
  name: "self-header-n",
  components: {
    selfMenu,
    selfDownload
  },
  data () {
    return {
      drawer: {
        show: false
      },
      menus: [],
      isRicepon: true
    }
  },
  computed: {
    isRiceponCn () {
      let isr = util.isRiceponCn();
      return isr;
    }
  },
  mounted () {
    let isRicepon = util.isRicepon();
    this.isRicepon = isRicepon;
    let menus = [];
    menus.push({ name: '首頁', go: '/', active: 'menu' });
    menus.push({ name: '關於Ricepon', go: '/about', active: 'about' });

    if (isRicepon) {
      menus.push({
        name: '產品', go: '/products', active: 'products', child: [
          { name: 'GSPOS 點餐系統', go: '/gspos', active: 'gspos' },
          { name: 'GSA 手機點餐系統', go: '/gsa', active: 'gsa' },
          { name: 'GSQ 自助排隊系統', go: '/gsq', active: 'gsq' },
        ]
      });
    } else {
      menus.push({
        name: '產品', go: '/products', active: 'products'
      });
    }
    if (isRicepon) {
      menus.push({
        name: '飯糰', go: '/ricepon', active: 'ricepon', child: [
          { name: 'QR code點餐', go: '/qrcode', active: 'qrcode' },
          { name: '會員系統', go: '/vip', active: 'vip' },
          { name: '外賣/自取', go: '/takeOutOrPickUp', active: 'takeOutOrPickUp' },
          { name: '網上訂座', go: '/lineUp', active: 'lineUp' },
          { name: '排隊取票', go: '/reservation', active: 'reservation' },
        ]
      });
    }
    // if (!isRicepon)
    //   menus.push({ name: '網上付款服務 PayMe for Business (快將推出)', go: "/business", active: 'business' });
    if (isRicepon) {
      menus.push({
        name: '客戶案例', go: '/case', active: 'case', child: [
          { name: '別府集團', go: '/biefu', active: 'biefu' },
          { name: '大喜屋集團', go: '/daxiwu', active: 'daxiwu' },
          { name: '黃珍珍泰國菜館', go: '/huangzhengzheng', active: 'huangzhengzheng' },
        ]
      });
    } else {
      menus.push({ name: "我們的客戶", go: '/case', active: 'case' });
    }

    menus.push({ name: '聯絡我們', go: '/contact', active: 'contact' });
    if (isRicepon)
      menus.push({ name: '免費試用', go: '/freeTrial', active: 'freeTrial' });
    this.menus = menus;
  },
  methods: {
    checkLanguage (e) {
      localStorage.setItem('ricepon.cn.i18n', e)
      this.$i18n.locale = e;
      location.reload();
    },
    getImgPath (t) {
      return util.getImgPath(t);
    },
    goUrl (t) {
      let pathname = location.pathname;
      if (pathname.indexOf(t) == -1 || (t == '/' && pathname != t)) {
        this.$router.push({
          path: t
        });
      }
    }
  },
  props: {
    // true 顯示 大的logo  false  顯示 小的
    type: {
      default: '1000'
    },
    ad: {
      default: 'menu'
    },
    child: {
      default: ''
    }
  }
}
</script>
<style lang="less" scoped>
.self-header {
  width: 100%;
  background-color: #fff;
  .header_1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 12;
    background-color: #fff;
    box-shadow: 0 1px 6px #ccc;
    img {
      height: 60px;
      margin-left: 30px;
    }
    .items {
      display: flex;
      margin-right: 30px;
      height: 100%;
      align-items: center;
      .item-active {
        &::after {
          background-color: #ab0000 !important;
        }
        &:hover::after {
          animation: none !important;
        }
      }
      .item {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 15px;
        position: relative;
        &::after {
          position: absolute;
          content: "";
          height: 2px;
          left: 0;
          right: 0;
          bottom: 30px;
          background-color: #fff;
        }
        &:hover::after {
          background-color: #ab0000;
          -webkit-animation: myhover 0.5s infinite; /* Chrome, Safari, Opera */
          animation: myhover 0.5s linear;
        }
        & /deep/ .el-dropdown {
          height: 100%;
          .el-dropdown-link {
            height: 100%;
            display: flex;
            align-items: center;
            font-size: 16px;
            color: #000;
          }
        }
      }
      .btn {
        padding: 0px 20px;
        height: 36px;
        text-align: center;
        line-height: 36px;
        font-size: 16px;
        color: #fff;
        border-radius: 5px;
        margin-left: 30px;
      }
    }
  }
  .header_2 {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-shadow: 0 1px 2px #ccc;
    background-color: #fff;
    img {
      height: 24px;
    }
  }
}

.el-dropdown-menu {
  padding: 28px 0;
  li {
    padding: 0;
    font-size: 16px;
    line-height: 24px;
    padding: 12px 24px;
    // min-width: 220px;
    &:hover {
      color: #ab0000 !important;
      // color: #fff !important;
      background-color: #fff;
    }
    div {
      padding: 0 10px;
    }
    .child-active {
      color: #ab0000;
      // color: #fff;
    }
  }
}
</style>
