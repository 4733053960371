<!--
 * @Author: lbh
 * @Date: 2021-04-23 16:38:57
 * @LastEditors: lbh
 * @LastEditTime: 2022-03-26 16:16:24
 * @Description: 產品
-->
<template>
  <div
    class="products t-body"
    :style="`min-height:${window.bodyH}px;`"
  >
    <selfHeaderN
      ad="products"
      child='deliverySystem'
      :type="window.type"
    />
    <div
      class="s-body"
      :style="window.type < 1000 ? 'padding-top:0;' : ''"
    >
      <selfTakeawaySystem :width="window.w"></selfTakeawaySystem>
      <div class="t-title">使用場景</div>
      <selfTakeawayItem :type="window.w"></selfTakeawayItem>
      <selfMap />
      <div class="setting-box">
        <div class="title">一體化智能餐飲管理服務專家，全方位保障您的成功！</div>
        <div class="desc">助您省心省力又省錢</div>
        <div class="btns">
          <el-button @click="btnClick(1)">立即免費試用</el-button>
          <el-button @click="btnClick(2)">聯絡我們</el-button>
        </div>
      </div>
    </div>
    <selfFooter :type="window.type" />
    <self-contact />
  </div>
</template>
<script>
import selfHeaderN from '@/components/header/headerN';
import selfFooter from "@/components/footer/footer";
import selfTakeawaySystem from '@/components/commo/takeawaySystem';
import selfTakeawayItem from '@/components/commo/takeawayItem';
import selfMap from "@/components/commo/map";
import util from '@/js/utils/util';
export default {
  components: {
    selfHeaderN,
    selfTakeawaySystem,
    selfTakeawayItem,
    selfMap,
    selfFooter
  },
  data () {
    return {
      window: {
        type: '1000',
        bodyH: 0,
        backH: 0,
        w: 0,
      },
    };
  },
  mounted () {
    util.setTitle('外賣系統 – 飯糰有限公司');
    // 初始化窗口
    this.initWindow();
    // 監聽 窗口
    window.onresize = () => {
      this.initWindow();
    };
  },
  methods: {
    initWindow () {
      let W = util.W(),
        w = W.w,
        h = W.h;
      this.window.bodyH = h;
      this.window.backH = parseInt(w / 2.5);
      this.window.w = w;
      if (w < 700) {
        this.window.type = '600';
        this.window.backH = w / 0.7;
      } else if (w < 1000) {
        this.window.type = '900';
        this.window.backH = w / 0.7;
      } else {
        this.window.type = '1000';
      }
    },
    btnClick (i) {
      if (i == 1) {
        window.open('https://forms.gle/rrPFzYvHp5QeCWNQ7')
      } else {
        this.$router.push({
          name: "contact"
        })
      }
    }
  },
};
</script>
<style lang="less" scoped>
.products {
  background-color: rgb(249, 249, 249);
  .s-body {
    background: #fff;
    .t-title {
      font-family: "Source Han Sans CN";
      font-style: normal;
      font-weight: 500;
      text-align: center;
      font-size: 40px;
      color: #303133;
      margin: 120px 0 60px;
    }
    .setting-box {
      margin-top: 120px;
      background: #f7f7f7;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 60px 0;
      .title {
        font-family: "Source Han Sans CN";
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        color: #303133;
      }
      .desc {
        font-family: "Source Han Sans CN";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #606266;
        margin-top: 14px;
      }
      .btns {
        margin-top: 40px;
        & /deep/ .el-button {
          &:nth-child(1) {
            background: #be1c42;
            border-color: #be1c42;
            color: #fff;
            border-radius: 4px;
            width: 156px;
          }
          &:nth-child(2) {
            border-color: rgb(191, 29, 66);
            color: #be1c42;
            border-radius: 4px;
            width: 156px;
            &:hover,
            &:active,
            &:focus {
              background-color: rgba(191, 29, 66, 0.3);
            }
          }
        }
      }
    }
  }
}
</style>
