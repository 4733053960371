<!--
 * @Author: lzh
 * @Date: 2022-03-10 14:32:33
 * @LastEditors: lbh
 * @LastEditTime: 2022-03-26 16:14:18
 * @Description: file content
-->
<template>
  <div class="takeaway-system-box">
    <div class="top">
      <img
        class="img"
        id="topImg"
        :src="getImgPath('wm_head.png')"
      />
      <div
        class="assistant-style"
        :style="`height:${imgHeight}px;top:${myTop}px;`"
      >
        <div
          class="left-box"
          :class="showLR ? 'fadeC' : 'fadeH'"
        >
          <div>多渠道QR Code入口</div>
          <div>多種支付方式</div>
          <div>T+7到賬</div>
          <div>支持線上及到店支付</div>
          <div>簡易餐牌</div>
        </div>
        <div
          class="right-box"
          :class="showLR ? 'fadeC' : 'fadeH'"
        >
          <div>無須年費</div>
          <div>自定義配送範圍</div>
          <div>不限單數</div>
          <div>自定義最低消費</div>
          <div>專人協助登記及開通</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import util from '@/js/utils/util';
export default {
  name: 'self-takeaway-system',
  components: {},
  data () {
    return {
      window: {
        type: '1000',
        bodyH: 0,
        backH: 0,
        w: 0,
      },
      imgHeight: '0',
      myTop: '0',
      showLR: false
    };
  },
  props: {
    width: {
      default () {
        return 0;
      }
    }
  },
  watch: {
    width () {
      console.log(this.width);
      this.initWindow();
    },
  },
  mounted () {
    // 初始化窗口
    setTimeout(() => {
      this.initWindow();
    }, 100);
  },

  methods: {
    initWindow () {
      let W = util.W(),
        w = W.w,
        h = W.h;
      this.window.bodyH = h;
      this.window.backH = parseInt(w / 2.5);
      this.window.w = w;
      if (w < 700) {
        this.window.type = '600';
        this.window.backH = w / 0.7;
      } else if (w < 1000) {
        this.window.type = '900';
        this.window.backH = w / 0.7;
      } else {
        this.window.type = '1000';
      }

      let time = 0;
      let f = () => {
        let imgEle = document.getElementById('topImg');
        console.log(imgEle);
        if (imgEle) {
          let a = util.getStyle(imgEle, 'height');
          console.log(a);
          let b = a.split('px')[0];
          if (b != 0) {
            this.imgHeight = b * 0.8;
            this.myTop = b * 0.18;
            this.showLR = true;
          } else {
            if (time < 10) {
              time++;
              setTimeout(() => {
                f();
              }, 50);
            }
          }
        }
      }
      f();
    },
    getImgPath (t) {
      return util.getImgPath(t);
    },
  },
};
</script>

<style lang="less" scoped>
.takeaway-system-box {
  position: relative;
  width: 100%;
  background-color: #f9f9f9;
  .top {
    .img {
      width: 100%;
    }
    .assistant-style {
      position: absolute;
      top: 6%;
      right: 23%;
      width: 15%;
      height: 77%;
      .left-box,
      .right-box {
        position: absolute;
        top: -20px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
        & > div {
          padding: 8px 24px;
          background: rgba(255, 255, 255, 0.6);
          box-shadow: 0px 4px 16px rgba(96, 98, 102, 0.2);
          font-family: "Source Han Sans CN";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #606266;
          width: max-content;
        }
      }
      .fadeC {
        animation: fade;
        animation-duration: 2s;
      }
      .fadeH {
        opacity: 0;
      }
      .left-box {
        width: 200px;
        left: -220px;
        & > div {
          animation: animation 6s ease-in-out infinite;
          // animation: animation 3s infinite alternate;
        }
        div {
          border-radius: 24px 24px 2px 24px;
          align-self: flex-end;
        }
        :nth-child(1) {
          animation-delay: 0s;
          margin-right: 38px;
        }
        :nth-child(2) {
          margin-right: 18px;
          animation-delay: -0.5s;
        }
        :nth-child(3) {
          animation-delay: -1s;
          margin-right: 34px;
          background: rgba(190, 28, 66, 0.6);
          color: #fff;
        }
        :nth-child(4) {
          animation-delay: -1.5s;
          color: #be1c42;
          margin-right: 42px;
        }
        :nth-child(5) {
          margin-right: 34px;
          animation-delay: -2s;
        }
      }
      .right-box {
        width: 200px;
        right: -220px;
        top: -30px;
        & > div {
          animation: animation2 8s ease-in-out infinite;
        }
        div {
          border-radius: 24px 24px 24px 2px;
          align-self: flex-start;
        }
        :nth-child(1) {
          margin-left: 30px;
          background: rgba(190, 28, 66, 0.6);
          color: #fff;
          animation-delay: 0s;
        }
        :nth-child(2) {
          color: #be1c42;
          margin-left: 18px;
          animation-delay: -0.5s;
          animation-duration: 5000ms;
        }
        :nth-child(3) {
          margin-left: 18px;
          animation-delay: -1s;
        }
        :nth-child(4) {
          background: rgba(190, 28, 66, 0.6);
          color: #fff;
          margin-left: 40px;
          animation-delay: -1.5s;
          animation-duration: 5500ms;
        }
        :nth-child(5) {
          margin-left: 14px;
          animation-duration: 4500ms;
          animation-delay: -2s;
        }
      }
    }

    @keyframes animation {
      from,
      to {
        transform: translate(6%, 0%);
      }
      50% {
        transform: translate(-10%, 0%);
      }
    }
    @keyframes animation2 {
      from,
      to {
        transform: translate(-10%, 0%);
      }
      50% {
        transform: translate(12%, 0%);
      }
    }

    @keyframes fade {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 0.5;
      }
      100% {
        opacity: 1;
      }
    }
  }
}
</style>
