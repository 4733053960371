<!--
 * @Author: lzh
 * @Date: 2022-03-10 14:32:33
 * @LastEditors: lbh
 * @LastEditTime: 2022-03-26 16:08:30
 * @Description: 使用場景
-->
<template>
  <div class="takeaway-item-box">
    <div
      class="items"
      v-for="(item, index) in itemData"
      :key="index"
      :class="window.class_"
      :style="`${check(index) ? `` : `flex-direction: row-reverse;`}`"
    >
      <div class="left">
        <div
          class="theme_color number"
          v-text="'0' + (index + 1)"
        ></div>
        <div
          v-text="item.title"
          class="title"
        ></div>
        <div
          v-for="(item2, index2) in item.content"
          :key="index2"
          class="content"
          :class="index2 == 0 ? 'zero' : ''"
          v-text="item2"
        ></div>
      </div>
      <div class="right">
        <img :src="item.img" />
      </div>
    </div>
  </div>
</template>

<script>
import util from '@/js/utils/util';
export default {
  name: 'self-takeaway-item',
  components: {},
  data () {
    return {
      itemData: [
        {
          title: '多渠道入口',
          content: [
            '餐廳專屬外賣落單QR Code',
            'Facebook/Instagram/Whatsapp都係您嘅宣傳渠道',
            '系統自動接單',
          ],
          img: util.getImgPath('wm_01.png'),
        },
        {
          title: '客人輕鬆落單',
          content: [
            '餐廳定制外賣餐牌',
            '無須用App落單',
            '憑取餐碼輕鬆自取',
            '支援到店支付及多種線上支付方式',
          ],
          img: util.getImgPath('wm_02.png'),
        },
        {
          title: '線上支付',
          content: [
            '支持多種支付方式',
            'Visa/Mastercard/Payme/AlipayHK/WechatPay',
            '固定週期結算交易金額',
          ],
          img: util.getImgPath('wm_03.png'),
        },
        {
          title: '選擇不同外送方式',
          content: ['支持多種物流', '根據距離劃分收費', '商戶可自行外送'],
          img: util.getImgPath('wm_04.png'),
        },
        {
          title: '安心又放心享受外賣',
          content: ['有助減少與客人接觸', '餐廳減省樓面人手'],
          img: util.getImgPath('wm_05.png'),
        },
      ],
      window: {
        class_: '',
      },
    };
  },
  props: {
    type: {
      default () {
        return '0';
      },
    },
  },
  watch: {
    type () {
      console.log(22, this.type);
      this.initWindow();
    },
  },
  mounted () {
    // 初始化窗口
    this.initWindow();
  },
  methods: {
    initWindow () {
      let w = this.type;
      if (w < 700) {
        this.window.class_ = 'items-column';
        if (w < 500) {
          this.window.class_ = 'items-column_s';
        }
      } else if (w < 1000) {
        this.window.class_ = 'items-column';
      } else {
        this.window.class_ = '';
      }
    },
    getImgPath (t) {
      return util.getImgPath(t);
    },
    check (index) {
      if (this.type == 1) return index % 2 != 0;
      else return index % 2 == 0;
    },
  },
};
</script>

<style lang="less" scoped>
.takeaway-item-box {
  margin: 30px 10% 0;
  border-radius: 6px;
  background-color: #fff;
  .items-column,
  .items-column_s {
    flex-direction: column-reverse !important;
    align-items: center;
    .right {
      width: 100% !important;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 50px;
    }
    .left {
      margin-bottom: 40px;
      width: 100% !important;
      align-items: center;
    }
  }
  .items {
    border-radius: 6px;
    display: flex;
    background-color: #fff;
    .left {
      width: calc(50% - 30px);
      display: flex;
      flex-direction: column;
      padding: 40px 0 10px 30px;
      background-color: #f7f7f7;
      .title {
        font-family: "Source Han Sans CN";
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        color: #303133;
        margin: 16px 0 40px 0;
      }
      .content {
        font-family: "Source Han Sans CN";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #606266;
        margin-top: 16px;
      }
      .zero {
        margin-top: 0;
      }
      .number {
        font-family: "OPPOSans";
        font-style: normal;
        font-weight: 400;
        font-size: 36px;
        color: #be1c42;
      }
    }
    .right {
      width: 50%;
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
        height: 100%;
      }
    }
  }
}
</style>
