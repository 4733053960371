<!--
 * @Author: lbh
 * @Date: 2021-04-21 18:23:05
 * @LastEditors: lbh
 * @LastEditTime: 2024-02-29 14:48:42
 * @Description: file content
-->
<template>
  <div class="self-footer-box">
    <div
      class="self-footer"
      :class="type == '900' ? 'self-footer_1' : type == '600' ? 'self-footer_2' : ''"
    >
      <div class="items_1">
        <h4>{{ $t('footer["Company Information"]') }}</h4>
        <div v-if="isRiceponCn">{{ $t('company.riceponCn') }}</div>
        <div v-else>{{ $t('footer["Company Name"]') }}</div>
        <div v-if="!isRicepon">{{$t('footer["Contact number (Hong Kong)"]')}}: +852 8200 2022</div>
        <div v-if="!isRiceponCn">
          {{$t('footer["mailbox"]')}}：
          <a
            href="mailto:info@ricepon.com"
            target="_blank"
          >info@ricepon.com</a>
        </div>
        <div v-if="isRicepon">
          {{$t('footer.address.label')}}： {{isRiceponCn ? $t('footer.address.riceponCn') : $t('footer.address.ricepon')}}
        </div>
        <div v-else> {{$t('footer.address.label')}}： {{isRiceponCn ? $t('footer.address.riceponCn') : $t('footer.address.ricepon')}}</div>
      </div>
      <div
        class="items_1"
        v-if="!isRicepon"
      >
        <h4>{{ $t('footer["Our social platform"]') }}</h4>
        <div class="item">
          <a
            class="pointer-hover"
            href="https://www.facebook.com/gspos"
          >
            <img src="../../assets/default/Facebook@2x.png" />
            Facebook
          </a>
        </div>
      </div>
      <div
        class="items_2"
        v-if="isRicepon"
      >
        <h4>{{ $t('footer["Corporate Business"]') }}</h4>
        <div class="item">
          <div
            class="pointer-hover"
            @click="goUrl('/gspos')"
          >{{ $t('footer["GSPOS order placement system"]') }}</div>
          <div
            class="pointer-hover"
            @click="goUrl('/gsa')"
          >{{ $t('footer["Mobile ordering system"]') }}</div>
        </div>
        <div class="item">
          <div
            class="pointer-hover"
            @click="goUrl('/gsq')"
          >{{ $t('footer["Self pickup queuing system"]') }}</div>
          <!-- <div
            class="pointer-hover"
            @click="goUrl('/reservation')"
          >{{ $t('footer["Online booking"]') }}</div> -->
          <div
            class="pointer-hover"
            @click="goUrl('/vip')"
          >{{ $t('footer["MembershipSystem"]') }}</div>
        </div>
        <div class="item">
          <div
            class="pointer-hover"
            @click="goUrl('/takeOutOrPickUp')"
          >{{ $t('footer["Delivery"]') }}</div>
          <div
            class="pointer-hover"
            @click="goUrl('/qrcode')"
          >{{ $t('footer["Scan Order"]') }}</div>
        </div>
        <!-- <div class="item"> -->
          <!-- <div
            class="pointer-hover"
            @click="goUrl('/lineUp')"
          >{{ $t('footer["Queuing for number retrieval"]') }}</div> -->

        <!-- </div> -->
        <div class="item">
          <div
            class="pointer-hover"
            @click="goUrl('/game')"
          >{{ $t('footer["Games and Marketing"]') }}</div>
        </div>
      </div>
      <div
        class="items_3"
        v-if="isRicepon && !isRiceponCn"
      >
        <h4>{{ $t('footer["partner"]') }}</h4>
        <div>
          <a
            href="https://www.octopus.com.hk"
            target="_blank"
            rel="noopener noreferrer"
          >{{ $t('footer["Octopus"]') }}</a>
        </div>
        <!-- <div>openrice</div> -->
      </div>
      <div
        v-if="isRiceponCn"
        class="items_3 gj_gzh"
      >
        <h4>微信公众号</h4>
        <img src="../../assets/default/goujiang.jpg" />
      </div>
    </div>
    <div
      class="suoshu"
      v-if="isNews"
    >
      {{$t('footer.FilingNumber.label')}}:<a href="https://beian.miit.gov.cn">{{$t('footer.FilingNumber.value')}}</a>
    </div>
  </div>
</template>
<script>
import util from "@/js/utils/util";
export default {
  name: 'self-footer',
  data () {
    return {
      isRicepon: true,
      isNews: false,
      isCn: false
    }
  },
  computed: {
    isRiceponCn () {
      return util.isRiceponCn();
    }
  },
  mounted () {
    this.isRicepon = util.isRicepon();
    // 姜科
    if (location.origin.indexOf('.com.cn') > -1) {
      this.isNews = true;
    }
    // 夠姜  
    else if (location.origin.includes('ricepon.cn')) {
      this.isCn = true;
    }

  },
  created () { },
  methods: {
    goUrl (t) {
      let pathname = location.pathname;
      if (pathname.indexOf(t) == -1 || (t == '/' && pathname != t)) {
        this.$router.push({
          path: t
        });
      }
    }
  },
  props: {
    type: {
      default: '1000'
    }
  }
}
</script>
<style lang="less" scoped>
.self-footer_1 {
  padding: 0 20px 50px !important;
}
.self-footer_2 {
  padding: 0 20px 50px !important;
  .items_2 {
    margin-left: 0 !important;
  }
}
.self-footer {
  display: flex;
  background-color: #000;
  padding: 0 90px 55px 90px;
  color: #fff;
  flex-wrap: wrap;
  .items_1 {
    min-width: 40%;
    margin-top: 55px;
    margin-right: 30px;
    a {
      &:hover {
        color: #980000;
      }
      img {
        width: 40px;
        margin-right: 10px;
      }
    }
    h4 {
      margin-bottom: 25px;
    }
    div {
      padding: 5px 0;
    }
  }
  .items_2 {
    min-width: 30%;
    margin-top: 55px;
    margin-right: 30px;
    margin-left: auto;
    h4 {
      margin-bottom: 25px;
    }
    .item {
      padding: 5px 0;
    }
  }
  .items_3 {
    flex-grow: 1;
    margin-top: 55px;
    margin-left: auto;
    &.gj_gzh {
      h4 {
        height: 21px;
      }
      img {
        width: 100px;
      }
    }
    h4 {
      margin-bottom: 25px;
    }
    div {
      padding: 5px 0;
      a:hover {
        color: #980000;
      }
    }
  }

  a {
    color: #fff;
    text-decoration: none;
  }
  .item {
    display: flex;
    div:first-child {
      width: 150px;
    }
    div:hover {
      color: #900000;
    }
  }
}
.suoshu {
  background-color: #000;
  color: #fff;
  text-align: center;
  font-size: 14px;
  padding-bottom: 30px;
  a {
    color: #fff;
    text-decoration: none;
  }
}

@media screen and(max-width:500px) {
  &.gj_gzh {
    h4 {
      height: 0 !important;
      margin: 0 !important;
    }
    img {
      width: 200px !important;
    }
  }
}
</style>